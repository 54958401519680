var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-training"},[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productCategory.name),expression:"productCategory.name"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.productCategory.name.$error,
            },attrs:{"type":"text","placeholder":"Enter Name"},domProps:{"value":(_vm.productCategory.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.productCategory, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.productCategory.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.productCategory.name.required)?_c('span',[_vm._v("This field is required.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name Np")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productCategory.name_np),expression:"productCategory.name_np"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.productCategory.name_np.$error,
            },attrs:{"type":"text","placeholder":"Enter Name NP"},domProps:{"value":(_vm.productCategory.name_np)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.productCategory, "name_np", $event.target.value)}}}),(_vm.submitted && _vm.$v.productCategory.name_np.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.productCategory.name_np.required)?_c('span',[_vm._v("This field is required.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.productCategory.description),expression:"productCategory.description"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.productCategory.description.$error,
            },attrs:{"type":"text","placeholder":"Description"},domProps:{"value":(_vm.productCategory.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.productCategory, "description", $event.target.value)}}}),_vm._v(" "),(_vm.submitted && _vm.$v.productCategory.description.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.productCategory.description.required)?_c('span',[_vm._v("This field is required.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Description NP")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.productCategory.description_np),expression:"productCategory.description_np"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.productCategory.description_np.$error,
            },attrs:{"type":"text","placeholder":"Description NP"},domProps:{"value":(_vm.productCategory.description_np)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.productCategory, "description_np", $event.target.value)}}}),_vm._v(" "),(_vm.submitted && _vm.$v.productCategory.description_np.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.productCategory.description_np.required)?_c('span',[_vm._v("This field is required.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Slug")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productCategory.slug),expression:"productCategory.slug"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.productCategory.slug.$error,
            },attrs:{"type":"text","placeholder":"Slug"},domProps:{"value":(_vm.productCategory.slug)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.productCategory, "slug", $event.target.value)}}}),(_vm.submitted && _vm.$v.productCategory.slug.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.productCategory.slug.required)?_c('span',[_vm._v("This field is required.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Status")]),_c('div',[_c('switches',{staticClass:"ml-1 mb-0",attrs:{"type-bold":"false","color":"info","text-enabled":"active","text-disabled":"inactive"},model:{value:(_vm.productCategory.status),callback:function ($$v) {_vm.$set(_vm.productCategory, "status", $$v)},expression:"productCategory.status"}})],1)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required"},[_vm._v("Upload feature image.")]),_c('b-form-file',{class:{
                    'is-invalid':
                      _vm.submitted && _vm.$v.productCategory.featured_image.$error,
                  },attrs:{"plain":"","accept":"image/jpeg, image/png"},on:{"change":function($event){return _vm.onFileChange($event, 'featured_image')}}}),_c('output',[(_vm.featureImgUri)?_c('img',{staticClass:"preview-image",attrs:{"src":_vm.featureImgUri}}):_vm._e()]),(_vm.submitted && _vm.$v.productCategory.featured_image.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.productCategory.featured_image.required)?_c('span',[_vm._v("This field is required.")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required"},[_vm._v("Upload Thumb image.")]),_c('b-form-file',{class:{
                    'is-invalid':
                      _vm.submitted && _vm.$v.productCategory.thumb_image.$error,
                  },attrs:{"plain":"","accept":"image/jpeg, image/png"},on:{"change":function($event){return _vm.onFileChange($event, 'thumb_image')}}}),_c('output',[(_vm.thumbImageUri)?_c('img',{staticClass:"preview-image",attrs:{"src":_vm.thumbImageUri}}):_vm._e()]),(_vm.submitted && _vm.$v.productCategory.thumb_image.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.productCategory.thumb_image.required)?_c('span',[_vm._v("This field is required.")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"}},[(_vm.createLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit")],1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('h6',{staticClass:"mb-4"},[_vm._v("Product Category Info")])])}]

export { render, staticRenderFns }